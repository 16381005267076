const projects = [
    {
      id: 1,
      title: "Projet Netflix",
      img: "netflix.webp",
      techno: "Angular & Spring-Boot",
      description: "Ce projet a été réalisé dans le cadre de mes études, il utilise des technologies comme Spring-Boot pour le Back-end et Angular pour le Front-end.",
      // ------------- Project View ------------- //
      descriptionView:"Ce projet a été réalisé dans le cadre de mes études, il utilise des technologies comme Spring-Boot pour le Back-end et Angular pour le Front-end. L'objectif étant, pour le back, de faire une API Rest Spring-Boot sécurisé grace a la gestion des autorisartions via spring security ainsi que grace aux tokens (JWT), garantissant ainsi une protection optimale des ressources sensibles. Pour le Front-end j'ai opter pour Angular, Framework réputé et très utiliser sur le marché, m'a permis de créer une interface utilisateur réactive et hautement performante ",
      nbFonctionnalite:16,
      nbPage:8,
      gitLink:"https://github.com/EnzoDevFrance/Angular-Netflix",
      // ------------- Colors ------------- //
      background:"rgba(20, 20, 20, 0.95)",
      titre:"red",
      texte:"white",
      // ----- Nav Colors ----- //
      navbar:"#141414",
      logo:"red",
      button:"red",
      texteNav:"white",
      // ----- Images ----- //
      imgDesciption:require('../assets/iphone_netflix.webp'),
      img1:require('../assets/netflix-navbar.png'),
      img2:require('../assets/netflix-details.webp'),
      img3:require('../assets/netflix-home.png'),
      img4:require('../assets/netflix-footer.png'),
    },
    {
      id: 2,
      title: "E-commerce Front",
      img: "ecommerce2.png",
      techno: "React",
      description: "Ce projet illustre une boutique en ligne épurée, spécialisée dans la vente de plantes, développée avec React.",
            // ------------- Project View ------------- //
            descriptionView:"Ce projet illustre une boutique en ligne épurée, spécialisée dans la vente de plantes, développée avec React. L'interface utilisateur se distingue par sa simplicité et son efficacité, offrant aux visiteurs une expérience de navigation fluide et intuitive. Le panier lui-même est facilement accessible, résumant les articles sélectionnés, leurs prix, et le total, avec une option pratique pour vider le panier. Ce projet démontre une excellente utilisation de React pour créer des expériences utilisateurs engageantes et performantes dans le domaine du commerce électronique.",
            nbFonctionnalite:5,
            nbPage:1,
            gitLink:"https://github.com/EnzoDevFrance/E-commerce-Front-End-React",
      // ------------- Colors ------------- //
      background:"#262423",
      titre:"#5A9A30",
      texte:"white",
      // ----- Nav Colors ----- //
      navbar:"#171615",
      logo:"#5A9A30",
      button:"#5A9A30",
      texteNav:"white",
      // ----- Images ----- //
      imgDesciption:require('../assets/iphoneJungle.png'),
      img1:null,
      img2:null,
      img3:require('../assets/ecommerce.png'),
      img4:null,
    },
    {
        id: 3,
        title: "Projet Scrapping",
        img: "scrapping.png",
        techno: "Python",
        description: "Ce projet Python utilise le scraping web pour extraire des citations d'un site internet.",
              // ------------- Project View ------------- //
      descriptionView:"Ce projet Python utilise le scraping web pour extraire des citations d'un site internet. Il est réalisé avec les bibliothèques requests pour effectuer des requêtes HTTP et BeautifulSoup pour analyser et manipuler les données HTML. Le script parcourt le contenu d'une page de citations, en extrait le texte, l'auteur et les tags associés, puis les stocke dans une liste. Il vérifie ensuite la présence d'un lien vers une page suivante et répète le processus pour cette nouvelle page. Finalement, il écrit les données collectées dans un fichier CSV, créant ainsi une base de données localisée de citations.",
      nbFonctionnalite:1,
      nbPage:1,
      gitLink:"https://github.com/EnzoDevFrance/Scrapping",
        // ------------- Colors ------------- //
      background:"#244E71",
      titre:"#FFDD51",
      texte:"white",
      // ----- Nav Colors ----- //
      navbar:"#1E2933",
      logo:"#FFDD51",
      button:"#FFDD51",
      texteNav:"white",
            // ----- Images ----- //
            imgDesciption:require('../assets/pythonLogo.svg.png'),
            img1:require('../assets/scrappingNav.png'),
            img2:null,
            img3:require('../assets/scrappingImg.png'),
            img4:null,
      },
      {
        id: 4,
        title: "Projet Amazon",
        img: "amazonLogo.jpg",
        techno: "React & Spring-Boot",
        description: "Je travaille sur un clone de la plateforme Amazon, bâti sur Spring Boot pour le backend et React pour le frontend.",
              // ------------- Project View ------------- //
      descriptionView:"Je travaille sur un clone de la plateforme Amazon, bâti sur Spring Boot pour le backend et React pour le frontend. Le backend intègre l'authentification sécurisée via JWT, assurant la sécurité des données utilisateur. Le frontend offre une expérience réactive et intuitive, permettant une navigation aisée et une gestion efficace du processus d'achat. Ce projet en développement prévoit toutes les fonctionnalités essentielles pour une application e-commerce inspirée d'un géant du secteur.",
      nbFonctionnalite:16,
      nbPage:6,
      gitLink:"https://github.com/EnzoDevFrance",
        // ------------- Colors ------------- //
      background:"rgba(19, 25, 33, 0.95)",
      titre:"#FBAE15",
      texte:"white",
      // ----- Nav Colors ----- //
      navbar:"#131921",
      logo:"white",
      button:"#FBAE15",
      texteNav:"white",
            // ----- Images ----- //
            imgDesciption:require('../assets/amazonPhone.png'),
            img1:require('../assets/amazonNav.png'),
            img2:null,
            img3:require('../assets/amazonImg.png'),
            img4:null,
      },
      
  ];
  
  export default projects;